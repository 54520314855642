import { useNavigate } from 'react-router-dom';

import styled from '@emotion/styled';
import { useState } from 'react';
import { Helmet } from 'react-helmet';
import Button from '../ui/Button';
import useReports from './context/useReports';
import CreateReportModal from './CreateReportModal';
import ReportCard from './ReportCard';
import useConfiguration from '../useConfiguration';

const ReportsContainer = styled.div`
  width: 100%;
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  gap: 16px;
`;

const HeaderContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

const Layout = styled.div`
  padding: 32px;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  flex-grow: 1;
  gap: 32px;
  max-width: 1400px;
  width: 100%;
  background: var(--light-grey);
  background-blend-mode: multiply;
  background-image: url('/mountains.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom center;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
`;

const NoReport = styled.div`
  margin: auto;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
`;

const NewReportBlock = styled.div`
  background-color: transparent;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px dashed #000000;
  border-radius: 8px;
  gap: 16px;
  height: 150px;
  min-width: 185px;
  cursor: pointer;

  &:hover {
    border: 1px solid #000000;
    cursor: pointer;
    p {
      text-decoration: underline;
    }
  }
`;

const MessageContainer = styled.div`
  display: flex;
  text-align: justify;
`;

const Reports = () => {
  const navigate = useNavigate();
  const { reports, remove } = useReports();
  const [open, setOpen] = useState(false);
  const { configuration } = useConfiguration();

  if (!reports) return null;

  const handleReportClicked = (reportId: string) => () => {
    navigate(`/reports/${reportId}`);
  };

  const handleReportDeleted = (reportId: string) => async (event: any) => {
    return await remove(reportId);
  };

  if (!reports || reports.length === 0) {
    return (
      <Layout>
        <HeaderContainer>
          <p className="h2b">My reports</p>
        </HeaderContainer>
        <CreateReportModal
          open={open}
          setOpen={setOpen}
          onClose={() => {
            setOpen(false);
          }}
        />
        <ReportsContainer>
          <NoReport>
          <MessageContainer
            dangerouslySetInnerHTML={{
              __html: configuration['reports.introduction'],
            }}
          />
            <Button
              variant="contained"
              color="primary"
              onClick={() => setOpen(true)}
              sx={{ height: '56px' }}
            >
              Create your first report !
            </Button>
          </NoReport>
        </ReportsContainer>
      </Layout>
    );
  }

  return (
    <>
      <Helmet>
        <title>{`SALOMON`}</title>
      </Helmet>
      <Layout>
        <HeaderContainer>
          <p className="h2b">My reports ({reports.length})</p>
          <ButtonContainer>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setOpen(true)}
              sx={{ height: '56px' }}
            >
              Create new report
            </Button>
          </ButtonContainer>
        </HeaderContainer>

        <CreateReportModal
          open={open}
          setOpen={setOpen}
          onClose={() => {
            setOpen(false);
          }}
        />
        <ReportsContainer>
          {reports.map((report) => (
            <ReportCard
              report={report}
              key={report._id.toString()}
              onClick={handleReportClicked(report._id.toString())}
              onDelete={handleReportDeleted(report._id.toString())}
            />
          ))}
        </ReportsContainer>
      </Layout>
    </>
  );
};

export default Reports;
