import {
  Checkbox,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControlLabel,
  TextField,
} from '@mui/material';

import Button from '../ui/Button';

import * as yup from 'yup';

import styled from '@emotion/styled';
import { endOfYear, startOfYear } from 'date-fns';
import { useFormik } from 'formik';
import { useEffect, useRef, useState } from 'react';
import { GeneralInfos } from './CreateReportModal';

const validationSchema = yup.object({
  name: yup.string().required('Champ obligatoire'),
  startDate: yup.date().required('Champ obligatoire'),
  endDate: yup.date().required('Champ obligatoire'),
});

const FieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  > :first-child {
    margin-bottom: 1rem;
  }
`;

const DateFieldsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 1rem;
`;

interface Props {
  generalInfos: GeneralInfos;
  setGeneralInfos: (generalInfos: GeneralInfos) => void;
  onCancel: () => void;
  onNext: () => void;
  isYearlyReport: boolean;
  setIsYearlyReport: (isYearly: boolean) => void;
}

const ReportGeneralInfos = ({
  generalInfos,
  setGeneralInfos,
  onCancel,
  onNext,
  isYearlyReport,
  setIsYearlyReport
}: Props) => {
  const formik = useFormik({
    initialValues: generalInfos,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setGeneralInfos(values);
      onNext();
    },
  });
  const handleYearChanged = (year: number) => {
    formik.setFieldValue('startDate', startOfYear(new Date(year, 0, 1)));
    formik.setFieldValue('endDate', endOfYear(new Date(year, 11, 31)));
  };
  useEffect(() => {
    if (isYearlyReport) {
      handleYearChanged(formik.values.startDate.getFullYear());
    }
  }, [isYearlyReport]);
  return (
    <form onSubmit={formik.handleSubmit}>
      <DialogContent dividers={true}>
        <DialogContentText
          id="scroll-dialog-description"
          tabIndex={-1}
          component="div"
        >
          <FieldsContainer>
            <TextField
              id="name"
              name="name"
              label="Name"
              placeholder={`${formik.values.startDate.getFullYear()} Annual Report`}
              autoFocus
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
              fullWidth
            />
            {!isYearlyReport && (
              <DateFieldsContainer>
                <TextField
                  label="Start date"
                  type="date"
                  value={formik.values.startDate.toISOString().split('T')[0]}
                  onChange={(e) => formik.setFieldValue('startDate', new Date(e.target.value) || new Date())}
                  error={
                    formik.touched.startDate && Boolean(formik.errors.startDate)
                  }
                  fullWidth
                />

                <TextField
                  label="End date"
                  type="date"
                  value={formik.values.endDate.toISOString().split('T')[0]}
                  onChange={(e) => formik.setFieldValue('endDate', new Date(e.target.value) || new Date())}
                  error={
                    formik.touched.endDate && Boolean(formik.errors.endDate)
                  }
                  fullWidth
                />
              </DateFieldsContainer>
            )}
            {isYearlyReport && (
              <TextField
                id="year"
                type="number"
                name="year"
                label="Year"
                autoFocus
                value={formik.values.startDate.getFullYear()}
                onChange={(e) => handleYearChanged(parseInt(e.target.value))}
                error={
                  (formik.touched.startDate &&
                    Boolean(formik.errors.startDate)) ||
                  (formik.touched.endDate && Boolean(formik.errors.endDate))
                }
                fullWidth
              />
            )}
            <FormControlLabel
              control={
                <Checkbox
                  checked={!isYearlyReport}
                  onChange={(e) => setIsYearlyReport(!e.target.checked)}
                  name="isYearlyReport"
                  size="small"
                  sx={{
                    '& .MuiSvgIcon-root': { fontSize: 12 },
                  }}
                />
              }
              label="Custom period"
              sx={{
                '& .MuiTypography-root': { fontSize: 12 },
                justifyContent: 'flex-end',
                marginRight: 0,
              }}
            />
          </FieldsContainer>
        </DialogContentText>
      </DialogContent>
      <DialogActions
        sx={{ position: 'sticky', bottom: 0, background: 'white' }}
      >
        <Button onClick={onCancel}>Cancel</Button>
        <Button type="submit" variant="contained" color="primary">
          Next
        </Button>
      </DialogActions>
    </form>
  );
};

export default ReportGeneralInfos;
