export const authenticatedFetch = async (
  input: RequestInfo | URL,
  options: RequestInit | undefined = {},
  iteration: number = 0,
): Promise<Response> => {
  if (!options.headers) {
    options.headers = new Headers({
      Accept: 'application/json',
      'Content-Type': 'application/json; charset=UTF-8',
    });
  }

  const token = localStorage.getItem('token');
  if (token) {
    try {
      (options.headers as any).set(
        'Authorization',
        `Bearer ${JSON.parse(token)}`,
      );
    } catch (e) {}
  }
  options.credentials = 'include';

  const response = await fetch(input, options);
  if (response.status === 401) {
    throw new Error('Unauthorized');
  }
  return response;
};
