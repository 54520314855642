import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      dark: '#A5A5A5',
      main: '#000000',
      light: '#DDDDDD',
      contrastText: '#FFFFFF'
    },
    secondary: {
      dark: '#DDDDDD',
      main: '#FFFFFF',
      light: '#FFFFFF',
      contrastText: '#000000'
    },
    error: {
      main: '#D3756B',
    }
  }
});

export default theme;
