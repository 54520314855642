import styled from '@emotion/styled';
import { Period } from '@salomon/core';
import { useNavigate, useParams } from 'react-router-dom';
import Button from '../../ui/Button';
import { useProject } from '../context/useProject';
import { fixPeriodType, nbDays } from '../planning/Planning';

const Container = styled.div`
  padding-top: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const getTotalNbDays = (planning: Period[] | undefined) => {
  if (!planning || planning.length === 0) return 0;
  return planning.reduce(
    (total, period) => total + nbDays(fixPeriodType(period)),
    0,
  );
};

const PlanningParameter = () => {
  const { projectId } = useParams();
  const { project } = useProject(projectId);
  const navigate = useNavigate();
  if (!project) return null;
  return (
    <Container>
      {project.planning && project.planning.length > 0 ? (
        <p className="hxr description">
          Your activity is currently set for{' '}
          <b>{project.planning.length} periods</b> and{' '}
          <b>{getTotalNbDays(project.planning)} days</b>.
        </p>
      ) : (
        <p className="hxr description">
          Define here the period for which your activity <b>{project.name}</b>{' '}
          is used.
        </p>
      )}
      <Button onClick={() => navigate(`./planning`)}>Configure planning</Button>
    </Container>
  );
};

export default PlanningParameter;
