import styled from '@emotion/styled';
import { ReactElement } from 'react';
import { useQuery } from 'react-query';
import { authenticatedFetch } from '../../../authentication/authenticatedFetch';
import { ReactComponent as Chart } from '../../../ui/icons/chart.svg';
import { ReactComponent as Man } from '../../../ui/illustration/man.svg';
import { ReactComponent as Woman } from '../../../ui/illustration/woman.svg';
import Loader from '../../../ui/Loader';

const Container = styled.div`
  padding: 16px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: var(--dark-grey);
  color: white;
`;

const Stats = styled.div`
  width: 100%;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
`;

const getStats = async (): Promise<any> => {
  const response = await authenticatedFetch(
    `${process.env.REACT_APP_API_URL}/stats`,
  );
  if (!response.ok) return undefined;
  return await response.json();
};

const MotivationBlock = () => {
  const statsQuery = useQuery('stats', getStats);
  const { data: stats, isLoading } = statsQuery;
  if (isLoading) {
    return (
      <Container>
        <h2>Salomon Community</h2>
        <Loader fullPage={false} />
      </Container>
    );
  }
  if (!stats) return null;
  return (
    <Container>
      <h2>Salomon Community</h2>
      <Stats>
        <StatBlock img={<Athlete />} number={stats.userCount} unit="Athletes" />
        <StatBlock img={<Chart />} number={stats.projectCount} unit="Calculations" />
      </Stats>
    </Container>
  );
};

interface StatBlockProps {
  img: ReactElement;
  number: number;
  unit: string;
}

const StatContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  > .figure {
    display: flex;
    flex-direction: column;
  }
  svg {
    width: 100px;
    height: 100px;
  }
`;

const StatBlock = ({ img, number, unit }: StatBlockProps) => {
  if (typeof number === 'undefined' || typeof unit === 'undefined')
    return <></>;
  return (
    <StatContainer>
      {img}
      <div className="figure">
        <p className="h1b">{number}</p>
        <p className="h4r">{unit}</p>
      </div>
    </StatContainer>
  );
};

const AthleteContainer = styled.div`
  position: relative;
  width: 100px;
  height: 100%;
  > svg {
    position: absolute;
    width: 60%;
    top: -50px;
  }
  > svg:first-of-type {
    position: absolute;
    left: 0;
  }
  > svg:last-of-type {
    position: absolute;
    right: 0;
  }
`;

const Athlete = () => {
  return (
    <AthleteContainer>
      <Man />
      <Woman />
    </AthleteContainer>
  );
};

export default MotivationBlock;
