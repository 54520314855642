import { Dialog, DialogTitle, Step, StepLabel, Stepper } from '@mui/material';

import { endOfYear, startOfYear } from 'date-fns';
import { useState } from 'react';
import ReportActivities from './ReportActivities';
import ReportGeneralInfos from './ReportGeneralInfos';

interface CreateReportModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  onClose?: any;
}

export interface GeneralInfos {
  name: string;
  startDate: Date;
  endDate: Date;
}

const CreateReportModal = ({
  open,
  setOpen,
  onClose,
}: CreateReportModalProps) => {
  const [activeStep, setActiveStep] = useState<number>(1);
  const [isYearlyReport, setIsYearlyReport] = useState<boolean>(true);
  const [generalInfos, setGeneralInfos] = useState<GeneralInfos>({
    name: '',
    startDate: startOfYear(new Date()),
    endDate: endOfYear(new Date()),
  });

  const handleNextStep = () => {
    setActiveStep(2);
  };

  const handlePreviousStep = () => {
    setActiveStep(1);
  };

  const handleClose = () => {
    onClose && onClose();
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll="paper"
      id="create_modal"
      maxWidth={activeStep === 1 ? 'sm' : 'lg'}
      fullWidth
    >
      <DialogTitle
        id="scroll-dialog-title"
        sx={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <div>
        <p className='h2b'>{`${activeStep === 2 ? `${generalInfos.name}` : 'New report'}`}</p>
        {activeStep === 2 && <p className='h4r'>{`${generalInfos.startDate.toLocaleDateString()} - ${generalInfos.endDate.toLocaleDateString()}`}</p>}
        </div>
        <Stepper activeStep={activeStep}>
          <Step
            key="informations"
            active={activeStep === 1}
            completed={activeStep > 1}
          >
            <StepLabel>General Information</StepLabel>
          </Step>
          <Step
            key="activities"
            active={activeStep === 2}
            completed={activeStep > 2}
          >
            <StepLabel>Selection of the activities</StepLabel>
          </Step>
        </Stepper>
      </DialogTitle>
      {activeStep === 1 && (
        <ReportGeneralInfos
          generalInfos={generalInfos}
          isYearlyReport={isYearlyReport}
          setIsYearlyReport={setIsYearlyReport}
          setGeneralInfos={setGeneralInfos}
          onCancel={handleClose}
          onNext={handleNextStep}
        />
      )}
      {activeStep === 2 && (
        <ReportActivities
          generalInfos={generalInfos}
          onCancel={handleClose}
          onPrevious={handlePreviousStep}
        />
      )}
    </Dialog>
  );
};

export default CreateReportModal;
