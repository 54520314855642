import styled from '@emotion/styled';
import {
  Bar1D,
  Bar1DBar,
  Bar1DData,
  Period,
  Pie1D,
  Pie1DData,
  Project,
} from '@salomon/core';
import { endOfYear, startOfYear } from 'date-fns';
import { ReactNode } from 'react';
import useProjects from '../../../project/context/useProjects';
import {
  getNbDaysWithinPeriod,
  getNbPlanningDays,
  getPlanning,
} from '../../../reports/ReportActivities';
import Bar1DComponent from '../../../results/Bar1DComponent';
import Pie1DComponent from '../../../results/Pie1DComponent';
import Loader from '../../../ui/Loader';

const Container = styled.div`
  padding: 16px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  background-color: white;
  border: black 1px solid;
`;

const Content = styled.div`
  width: 100%;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Element = styled.div`
  display: flex;
  height: 90%;
  width: calc(100% / 4);
  flex-direction: column;
  align-items: center;
  gap: 8px;
  justify-content: flex-end;
  p {
    text-align: center;
  }
`;

const ChartContainer = styled.div`
  width: 100%;
  height: 100%;
`;

const Layout = ({ children }: { children: ReactNode }) => {
  const currentYear = new Date().getFullYear();
  return (
    <Container>
      <h2>My {currentYear} impact</h2>
      {children}
    </Container>
  );
};

const ImpactBlock = () => {
  const { projects, loading, remove, count } = useProjects();
  if (loading)
    return (
      <Layout>
        <Loader fullPage={false} />
      </Layout>
    );
  if (!projects || projects.length === 0)
    return (
      <Layout>
        <Content style={{ justifyContent: 'center' }}>
          <p className="hxr" style={{ width: '50%' }}>
            Create your first activity and discover its statistics on your
            ongoing impact!
          </p>
        </Content>
      </Layout>
    );
  const { indicator, barByQuarter, pieByCategory, pieByActivity } =
    getData(projects);
  return (
    <Layout>
      <Content>
        {indicator.unit && indicator.number && (
          <Element>
            <Total indicator={indicator} />
            <p className="hzb">{indicator.title}</p>
          </Element>
        )}
        <Element>
          <ChartContainer>
            <Bar1DComponent result={barByQuarter} simple />
          </ChartContainer>
          <p className="hzb">By Quarter</p>
        </Element>
        <Element>
          <ChartContainer>
            <Pie1DComponent result={pieByActivity} />
          </ChartContainer>
          <p className="hzb">By Activity</p>
        </Element>
        <Element>
          <ChartContainer>
            <Pie1DComponent result={pieByCategory} />
          </ChartContainer>
          <p className="hzb">By Category</p>
        </Element>
      </Content>
    </Layout>
  );
};

const getData = (projects: Project[]) => {
  let ongoingTotal = 0;
  let barByQuarterData = getInitialBarData();
  let pieByCategoryData: Pie1DData[] = [];
  let pieByActivityData: Pie1DData[] = [];
  projects.forEach((project: Project) => {
    const totalIndicator = getTotalIndicator(project);

    if (totalIndicator) {
      const ongoingRatio = getProjectRatioWithinPeriod(
        project,
        getOngoingYear(),
      );

      //total indicator
      ongoingTotal = ongoingRatio
        ? ongoingTotal + totalIndicator * ongoingRatio
        : ongoingTotal;

      // pie by activity
      pieByActivityData.push({
        name: project.name,
        value: Math.round(totalIndicator * ongoingRatio),
        fill: 'black', //temporary color before changing it once array is complete
      });

      // pie by categories
      if (
        project.data.mainPie &&
        project.data.mainPie.data &&
        project.data.mainPie.data.length > 0
      ) {
        pieByCategoryData = mergePieData(
          [...pieByCategoryData],
          project.data.mainPie.data,
        );
      }

      //bar graph by quarter
      for (let index = 1; index < 5; index++) {
        const quarterPeriod = getQuarterPeriod(index);
        if (!quarterPeriod) continue;
        const quarterRatio = getProjectRatioWithinPeriod(
          project,
          quarterPeriod,
        );
        if (!quarterRatio) continue;
        let temp = barByQuarterData.data[index - 1][`Q${index}`] as number;
        barByQuarterData.data[index - 1][`Q${index}`] = Math.round(
          temp + quarterRatio * totalIndicator,
        );
      }
    }
  });
  const { indicatorUnit, indicatorTitle } = getIndicatorData(projects);
  const barByQuarter: Bar1D = {
    type: 'bar1D',
    unit: indicatorUnit,
    ...barByQuarterData,
  };
  const pieByCategory: Pie1D = {
    type: 'pie1D',
    unit: indicatorUnit,
    data: pieByCategoryData,
  };
  const pieByActivity: Pie1D = {
    type: 'pie1D',
    unit: indicatorUnit,
    data: getPieActivityWithColors(pieByActivityData),
  };
  return {
    indicator: {
      number: ongoingTotal,
      unit: indicatorUnit,
      title: indicatorTitle,
    },
    barByQuarter,
    pieByCategory,
    pieByActivity,
  };
};

const colors = [
  '#E34229',
  '#3B76B5',
  '#40AC9A',
  '#5E1E27',
  '#FFB03A',
  '#E50958',
  '#D0D0D0',
  '#5E1E27',
];

const getPieActivityWithColors = (pieData: Pie1DData[]) => {
  return [...pieData]
    .sort((a, b) => b.value - a.value)
    .map((data, index) => {
      const color = colors[index] || getRandomColor();
      data.fill = color;
      return data;
    });
};

const getRandomColor = (): string => {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

const mergePieData = (pie1: Pie1DData[], pie2: Pie1DData[]): Pie1DData[] => {
  for (const item2 of pie2) {
    const matchingItem1 = pie1.find((item1) => item1.name.toLowerCase() === item2.name.toLowerCase());
    if (matchingItem1) {
      // If there is a matching item in pie1, add its value to pie2 element value
      matchingItem1.value += item2.value;
    } else {
      // If no matching item in pie1, push pie2 element to pie1
      pie1.push(item2);
    }
  }
  pie1.map((category) => {
    category.value = Math.round(category.value);
    return category;
  });
  return pie1;
};

const getInitialBarData = () => {
  let data: Bar1DData[] = [1, 2, 3, 4].map((index) => {
    return { [`Q${index}`]: 0, name: `Q${index}` };
  });
  const bars: Bar1DBar[] = [1, 2, 3, 4].map((index) => {
    return { name: `Q${index}`, color: colors[index] };
  });
  return {
    data,
    bars,
  };
};

const getTotalIndicator = (project: Project) => {
  if (!project.data || !('mainIndicator' in project.data)) {
    return 0;
  }
  return project.data.mainIndicator.number;
};

const getIndicatorData = (projects: Project[]) => {
  const projectWithIndicator = projects.find(
    (project) => project.data && project.data.mainIndicator,
  );
  if (projectWithIndicator) {
    const { unit, title } = projectWithIndicator.data.mainIndicator;
    return { indicatorUnit: unit, indicatorTitle: title };
  }
  return { indicatorUnit: undefined, indicatorTitle: undefined };
};

const getProjectRatioWithinPeriod = (
  project: Project,
  referencePeriod: Period,
) => {
  const planning = getPlanning(project);
  if (!planning) return 0;
  const nbDaysOfProject = getNbPlanningDays(planning);
  const nbDaysWithinPeriod = getNbDaysWithinPeriod(
    planning,
    new Date(referencePeriod.startDate),
    new Date(referencePeriod.endDate),
  );
  return nbDaysWithinPeriod / nbDaysOfProject;
};

const getOngoingYear = (): Period => {
  const startDate = startOfYear(new Date());
  const endDate = endOfYear(new Date());
  return { startDate, endDate };
};

const getQuarterPeriod = (
  quarterNumber: number,
): { startDate: Date; endDate: Date } | null => {
  if (quarterNumber < 1 || quarterNumber > 4) {
    console.error(
      'Invalid quarter number. Please provide a number between 1 and 4.',
    );
    return null;
  }

  const currentYear = new Date().getFullYear();
  let startDate: Date;
  let endDate: Date;

  switch (quarterNumber) {
    case 1:
      startDate = startOfYear(new Date());
      endDate = new Date(currentYear, 2, 31);
      break;
    case 2:
      startDate = new Date(currentYear, 3, 1);
      endDate = new Date(currentYear, 5, 30);
      break;
    case 3:
      startDate = new Date(currentYear, 6, 1);
      endDate = new Date(currentYear, 8, 30);
      break;
    case 4:
      startDate = new Date(currentYear, 9, 1);
      endDate = endOfYear(new Date());
      break;
    default:
      return null;
  }

  return { startDate, endDate };
};

interface TotalProps {
  indicator: {
    number: number;
    unit: string;
    title: string;
  };
}

const TotalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Total = ({ indicator }: TotalProps) => {
  return (
    <TotalContainer>
      <p className="h1b">{Math.round(indicator.number).toLocaleString()}</p>
      <p className="h5r">{indicator.unit}</p>
    </TotalContainer>
  );
};

export default ImpactBlock;
