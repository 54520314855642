import { useAuth0 } from '@auth0/auth0-react';
import { createContext, ReactNode, useContext, useRef } from 'react';
import { useLocalStorage } from 'usehooks-ts';

export interface RegisterParams {
  email: string;
  password: string;
  firstName?: string;
  lastName?: string;
  company?: string;
}

interface AuthenticationContextType {
  user: any;
  isAuthenticated: boolean;
  loading: boolean;
  login: () => Promise<void>;
  logout: () => Promise<void>;
  handleRedirectCallback: (url?: string) => Promise<any>;
}

export const AuthenticationContext = createContext<AuthenticationContextType>({
  user: null,
  isAuthenticated: false,
  loading: false,
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  handleRedirectCallback: () => Promise.resolve(),
});

AuthenticationContext.displayName = 'AuthenticationContext';

export const AuthenticationProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [token, setToken] = useLocalStorage<string | null>('token', null);
  const loading = useRef(false)

  const {
    logout,
    loginWithRedirect,
    user,
    isAuthenticated,
    isLoading,
    handleRedirectCallback,
    getAccessTokenSilently,
  } = useAuth0();

  const authenticationValues = {
    user,
    isAuthenticated,
    loading: isLoading || loading.current,
    login: () => {
      loading.current = true;
      return loginWithRedirect();
    },
    logout: () => {
      setToken(null);
      return logout({ logoutParams: { returnTo: window.location.origin } });
    },
    handleRedirectCallback: async () => {
      await handleRedirectCallback();
      const token = await getAccessTokenSilently();
      setToken(token);
      loading.current = false;
      setTimeout(() => {
        window.location.replace("/");
      }, 10);
    },
  };

  return (
    <AuthenticationContext.Provider value={authenticationValues}>
      {children}
    </AuthenticationContext.Provider>
  );
};

export const useAuthentication = () => {
  return useContext(AuthenticationContext);
};
