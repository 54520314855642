import styled from '@emotion/styled';
import AddIcon from '@mui/icons-material/Add';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import DeleteIcon from '@mui/icons-material/Delete';
import { Alert, Tooltip } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Button from '../../ui/Button';
import Loader from '../../ui/Loader';
import { useProject } from '../context/useProject';

const Container = styled.div`
  padding: 32px;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  flex-grow: 1;
  gap: 32px;
  max-width: 1400px;
  width: 100%;
  background: var(--light-grey);
  background-blend-mode: multiply;
  background-image: url('/mountains.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom center;
`;

const HeaderContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

const Content = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 8px;
`;

const Row = styled.div`
  width: min(100%, 600px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  gap: 8px;
  padding: 8px 16px;
  border-radius: 16px;
  cursor: pointer;
  .nb-days {
    background-color: var(--yellow);
    padding: 6px 12px;
    border-radius: 16px;
  }
`;

interface Period {
  startDate: Date;
  endDate: Date;
}

export const fixPeriodType = (period: Period) => {
  return {
    startDate: fixDateType(period.startDate),
    endDate: fixDateType(period.endDate),
  };
};

const fixDateType = (value: string | Date) => {
  return new Date(value);
};

const checkDate = (value: any) => {
  if (value) {
    if (value instanceof Date) return format(value, 'yyyy-MM-dd');
    if (!isNaN(value))
      return format(
        new Date(Math.round((value - 25569) * 86400 * 1000)),
        'yyyy-MM-dd',
      );
  }
  return null;
};

export const nbDays = (period: Period): number => {
  const { startDate, endDate } = period;

  if (!startDate || !endDate) return 0;

  const utcStart = Date.UTC(
    startDate.getFullYear(),
    startDate.getMonth(),
    startDate.getDate(),
  );
  const utcEnd = Date.UTC(
    endDate.getFullYear(),
    endDate.getMonth(),
    endDate.getDate(),
  );

  const millisecondsPerDay = 24 * 60 * 60 * 1000;
  return Math.floor((utcEnd - utcStart) / millisecondsPerDay);
};

const Planning = () => {
  const { projectId } = useParams();
  const { project, updatePlanning, updateParameterAsync } =
    useProject(projectId);
  const [open, setOpen] = useState<boolean>(false);
  const [alert, setAlert] = useState<string>('');
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [index, setIndex] = useState<number | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [periods, setPeriods] = useState<Period[]>([]);

  useEffect(() => {
    if (project) {
      if (project.planning) {
        const formattedPeriods = project.planning.map((period: Period) => {
          return {
            startDate: new Date(period.startDate),
            endDate: new Date(period.endDate),
          };
        });
        setPeriods(formattedPeriods);
      }
    }
  }, [project]);

  if (!project) {
    return <Loader fullPage />;
  }

  const handleAddDates = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setStartDate(null);
    setEndDate(null);
    setIndex(null);
    setAlert('');
  };

  const handleSave = () => {
    if (!startDate || !endDate) {
      setAlert('One of the date is not set');
      return;
    }
    if (!(startDate < endDate)) {
      setAlert("The end date isn't posterior to start date");
      return;
    }
    let newPeriods = [];
    if (typeof index === 'number') {
      newPeriods = [...periods];
      newPeriods[index] = { startDate, endDate };
    } else {
      newPeriods = [...periods, { startDate, endDate }];
    }
    updatePlanning(newPeriods);
    const nbPeriodsDays: number = newPeriods.reduce(
      (total, period) => total + nbDays(period),

      0,
    );
    updateParameterAsync([
      {
        type: 'id',
        id: 'planning',
        value: nbPeriodsDays,
      },
    ]);
    setOpen(false);
    setStartDate(null);
    setEndDate(null);
    setIndex(null);
    setAlert('');
  };
  const handleEdit = (index: number) => {
    setIndex(index);
    setStartDate(periods[index].startDate);
    setEndDate(periods[index].endDate);
    setOpen(true);
  };

  const handleDelete = (
    e: React.MouseEvent<HTMLButtonElement>,
    index: number,
  ) => {
    e.preventDefault();
    e.stopPropagation();
    const editedPeriods = [...periods];
    editedPeriods.splice(index, 1);
    updatePlanning(editedPeriods);
  };
  return (
    <Container>
      <HeaderContainer>
        <p className="h2b">My Planning for activity "{project?.name}"</p>
        <Button
          variant="contained"
          color="primary"
          onClick={handleAddDates}
          sx={{ height: '56px' }}
          startIcon={<AddIcon />}
        >
          Add period
        </Button>
      </HeaderContainer>
      <Content>
        {periods.map((period, index) => (
          <Row key={index} onClick={() => handleEdit(index)}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <p className="h5r">{format(period.startDate, 'yyyy-MM-dd')}</p>
              <ArrowForwardIosIcon sx={{ margin: '0 8px' }} />
              <p className="h5r">{format(period.endDate, 'yyyy-MM-dd')}</p>
            </div>

            <p className="h5b nb-days">
              {nbDays(period) > 1 ? `${nbDays(period)} days` : '1 day'}
            </p>
            <Tooltip title="Delete period">
              <IconButton onClick={(e) => handleDelete(e, index)}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </Row>
        ))}
      </Content>
      {open && (
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Add period</DialogTitle>
          <DialogContent
            sx={{
              paddingTop: '16px !important',
              display: 'flex',
              flexDirection: 'column',
              gap: '16px',
            }}
          >
            {alert.length > 0 && <Alert severity="warning">{alert}</Alert>}
            <TextField
              label="Start Date"
              type="date"
              defaultValue={checkDate(startDate)}
              onChange={(e) => setStartDate(fixDateType(e.target.value))}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              label="End Date"
              type="date"
              defaultValue={checkDate(endDate)}
              onChange={(e) => setEndDate(fixDateType(e.target.value))}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="secondary">
              Cancel
            </Button>
            <Button onClick={handleSave} color="primary">
              Save
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Container>
  );
};

export default Planning;
