import { useParams } from 'react-router-dom';
import { ResultDispatch } from '../results/Results';
import { TitleBlock, ResultsContainer } from '../results/Results';
import { PageContainer } from '../ui/Container';
import useReport from './context/useReport';

const ReportProjectResults = () => {
  const { reportId, projectId } = useParams();
  const { report } = useReport(reportId);

  if (!report) {
    return null;
  }

  const project = report.projects.find(
    (project) => project._id.toString() === projectId,
  );

  if (!project) {
    return null;
  }

  return (
    <PageContainer>
      <TitleBlock style={{ color: "white" }}>
        <h3 className='h3b'>{report.name}</h3>
        <h4 className='hxr'>{project.name}</h4>
      </TitleBlock>
      <ResultsContainer>
        {project.results.map((result, index) => (
          <ResultDispatch result={result} key={index} />
        ))}
      </ResultsContainer>
    </PageContainer>
  );
};

export default ReportProjectResults;
