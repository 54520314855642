import styled from '@emotion/styled';
import React from 'react';

import { ReactComponent as Cycle } from '../ui/icons/categories/cycle.svg';
import { ReactComponent as Calendar } from '../ui/icons/categories/time-and-calendar.svg';

export const ProjectTypesIcons: { [key: string]: React.ReactNode } = {
  preparation: <Cycle />,
  race: <Calendar />,
};

const ProjectIllustrationContainer = styled.div`
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
`;

const ProjectText = styled.div`
  fill: #000000;
  width: 32px;
`;

const ProjectIllustration = ({ type }: { type: string }) => {
  return (
    <ProjectIllustrationContainer>
      <ProjectText>{ProjectTypesIcons[type]}</ProjectText>
    </ProjectIllustrationContainer>
  );
};

export default ProjectIllustration;
