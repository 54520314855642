import { Project } from '@salomon/core';

export const blocks = {
  report:{
    id: 'report',
    name: 'Rapports',
    color: 'var(--report-primary)',
    width: '20%',
  },
  site: {
    id: 'site',
    name: 'Sites',
    color: 'var(--model1-primary)',
    width: '80%',
    models: ['site'],
  },
  game: {
    id: 'game',
    name: 'Jeux',
    color: 'var(--model2-primary)',
    width: '100%',
    models: ['game'],
  },
};

export const dashboardText = {
  createButton: {
    //model as key
    site: 'site',
    game: 'jeu',
  },
  emptyBlock: {
    title: {
      //model as key
      site: 'Créez votre première année',
      game: 'Créez votre premier jeu',
    },
    description: {
      //model as key
      site: 'Empreinte environnementale annuel de votre site.',
      game: 'Empreinte environnementale de votre jeu.',
    },
  },
};

export const dashboardConfig = [
  {
    height: '34%',
    blocks: [blocks.report, blocks.site],
  },
  {
    height: '66%',
    blocks: [blocks.game],
  },
];

export const sortByDate = (a: Project, b: Project) => {
  return (
    (new Date(b.updatedAt) || new Date(b.createdAt)).getTime() -
    (new Date(a.updatedAt) || new Date(a.createdAt)).getTime()
  );
};
