import styled from '@emotion/styled';
import { ReactNode } from 'react';

const Container = styled.div`
  width: min(1400px, 100%);
`;

const Main = styled.main`
  width: 100%;
  height: calc(100vh - 132px);
  overflow: auto;
  overflow-x: visible;
`;

interface AppLayoutProps {
  header: ReactNode;
  children: ReactNode;
}

const AppFooter = styled.main`
  width: 100%;
  height: 40px;
  background-color: black;
`;

const AppLayout = ({ header, children }: AppLayoutProps) => {
  return (
    <Container>
      {header}
      <Main>{children}</Main>
      <AppFooter />
    </Container>
  );
};

export default AppLayout;
