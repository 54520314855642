import { useParams } from 'react-router-dom';
import { ResultDispatch } from '../results/Results';
import { TitleBlock, ResultsContainer } from '../results/Results';
import { PageContainer } from '../ui/Container';
import useReport from './context/useReport';
import { Result } from '@salomon/core';

const Report = () => {
  const { reportId } = useParams();
  const { report } = useReport(reportId);

  if (!report) {
    return null;
  }

  return (
    <PageContainer>
      <TitleBlock style={{ color: "white" }}>
        <h3 className='h3b'>{report.name}</h3>
        <p className='hxr'>{new Date(report.startDate).toLocaleDateString()} - {new Date(report.endDate).toLocaleDateString()}</p>
      </TitleBlock>
      <ResultsContainer>
        {report.results.map((result: Result, index: number) => (
          <ResultDispatch result={result} key={index} />
        ))}
      </ResultsContainer>
    </PageContainer>
  );
};

export default Report;
