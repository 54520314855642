import styled from '@emotion/styled';

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';

import {
  addDays,
  areIntervalsOverlapping,
  differenceInCalendarDays,
  isAfter,
  isBefore,
  subDays,
} from 'date-fns';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { Period, Project } from '@salomon/core';

const PeriodsContainer = styled.p`
  height: 48px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: orange;
  padding: 0 16px;
  gap: 32px;
  color: black;
  > div:first-of-type {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;

interface PeriodsProps {
  nbPlanningDays: number;
  nbReportDays: number;
}

const ReportPeriods = ({
  nbPlanningDays,
  nbReportDays,
}: PeriodsProps) => {
  return (
    <PeriodsContainer>
      <div>
        <p className="hxr">
          <b>{nbReportDays} days / </b> {nbPlanningDays}
        </p>
        <p className="hzr">Comprised within report period</p>
      </div>
    </PeriodsContainer>
  );
};

interface PeriodExplanationProps {
  open: boolean;
  onClose: () => void;
  project: Project;
  planning: Period[];
  reportStartDate: Date;
  reportEndDate: Date;
}

const PeriodExplanationContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const PeriodExplanation = ({
  open,
  onClose,
  project,
  planning,
  reportStartDate,
  reportEndDate,
}: PeriodExplanationProps) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Detail planning for {project.name}</DialogTitle>
      <DialogContent dividers={true}>
        <DialogContentText>
          List of periods of the activity. Green if totaly comprised within
          report period. Red if totally outside report period. Blue otherwise.
          In which case period is separated into two periods : one within, one
          without
          <br />
          <br />
        </DialogContentText>
        <PeriodExplanationContainer>
          {planning?.map((planning) => (
            <PeriodRow
              planning={planning}
              reportStartDate={reportStartDate}
              reportEndDate={reportEndDate}
            />
          ))}
        </PeriodExplanationContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

interface PeriodRowProps {
  planning: Period;
  reportStartDate: Date;
  reportEndDate: Date;
}

const PeriodContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  > div:first-of-type {
    display: flex;
    gap: 8px;
    align-items: center;
    padding: 8px 16px;
  }
  .sub_period_container {
    display: flex;
    align-items: center;
    gap: 8px;
  }
`;

const PeriodRow = ({
  planning,
  reportStartDate,
  reportEndDate,
}: PeriodRowProps) => {
  const periodStart = new Date(planning.startDate);
  const periodEnd = new Date(planning.endDate);

  const isPeriodOutsideReport = !areIntervalsOverlapping(
    {
      start: periodStart,
      end: periodEnd,
    },
    { start: reportStartDate, end: reportEndDate },
  );

  const isPeriodWithinReport =
    !isPeriodOutsideReport &&
    isBefore(reportStartDate, periodStart) &&
    isAfter(reportEndDate, periodEnd);

  if (isPeriodOutsideReport)
    return (
      <PeriodContainer>
        <Dates type="error" dateStart={periodStart} dateEnd={periodEnd} />
      </PeriodContainer>
    );

  if (isPeriodWithinReport)
    return (
      <PeriodContainer>
        <Dates type="ok" dateStart={periodStart} dateEnd={periodEnd} />
      </PeriodContainer>
    );

  // case where period and report have a partial intersection

  const reportStart =
    periodStart > reportStartDate ? periodStart : reportStartDate;
  const reportEnd = periodEnd < reportEndDate ? periodEnd : reportEndDate;
  const isOverlappingFirst = periodStart > reportStartDate;

  const dateStartFirstPeriod = isOverlappingFirst
    ? reportStart
    : periodStart;
  const dateEndFirstPeriod = isOverlappingFirst
    ? reportEnd
    : subDays(reportStart, 1);

  const dateStartSecondPeriod = isOverlappingFirst
    ? addDays(reportEnd, 1)
    : reportStart;
  const dateEndSecondPeriod = isOverlappingFirst
    ? periodEnd
    : reportEnd;

  return (
    <PeriodContainer>
      <Dates type="neutral" dateStart={periodStart} dateEnd={periodEnd} />
      <div className="sub_period_container">
        <SubdirectoryArrowRightIcon />
        <Dates
          type={isOverlappingFirst ? 'ok' : 'error'}
          dateStart={dateStartFirstPeriod}
          dateEnd={dateEndFirstPeriod}
          subPeriod
        />
      </div>
      <div className="sub_period_container">
        <SubdirectoryArrowRightIcon />
        <Dates
          type={isOverlappingFirst ? 'error' : 'ok'}
          dateStart={dateStartSecondPeriod}
          dateEnd={dateEndSecondPeriod}
          subPeriod
        />
      </div>
    </PeriodContainer>
  );
};

interface DatesProps {
  dateStart: Date;
  dateEnd: Date;
  type: string;
  subPeriod?: boolean;
}

const DatesContainer = styled.div`
  display: flex;
  width: fit-content;
  gap: 16px;
  align-items: center;
  padding: 4px 8px;
  &.period_ok {
    background-color: lightgreen;
  }
  &.period_error {
    background-color: pink;
  }
  &.period_neutral {
    background-color: lightblue;
  }
  .nb_days {
    padding: 6px 12px;
    background-color: black;
    color: white;
  }
`;

const Dates = ({ dateStart, dateEnd, type, subPeriod }: DatesProps) => {
  return (
    <DatesContainer
      className={subPeriod ? `period_${type} sub_period` : `period_${type}`}
    >
      {type === 'ok' && <CheckIcon />}
      {type === 'neutral' && <MoreHorizIcon />}
      {type === 'error' && <CloseIcon />}
      <p className="hxr">
        {`${dateStart.toLocaleDateString()} - ${dateEnd.toLocaleDateString()}`}
      </p>
      <p className="h6b nb_days">
        {`${
          differenceInCalendarDays(new Date(dateEnd), new Date(dateStart)) + 1
        } days`}
      </p>
    </DatesContainer>
  );
};

export default ReportPeriods;
