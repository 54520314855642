import styled from '@emotion/styled';
import { Helmet } from 'react-helmet';
import AppLayout from '../layout/AppLayout';
import { HeaderProjects as Header } from '../layout/Headers';
import LastActivities from './blocks/activities/LastActivities';
import ImpactBlock from './blocks/impact/ImpactBlock';
import MotivationBlock from './blocks/motivation/MotivationBlock';
import useModels from '../project/context/useModels';

const Layout = styled.div`
  display: flex;
  padding: 16px 0;
  flex-direction: column;
  height: 100%;
  flex-grow: 1;
  gap: 20px;
  max-width: 1400px;
  width: 100%;
  overflow: visible;
  @media (max-width: 1400px) {
    padding: 16px;
  }
`;

const Top = styled.div`
  display: flex;
  flex-direction: row;
  height: 200px;
  gap: 20px;
`;

const TopLeft = styled.div`
  width: 40%;
  height: 100%;
`;

const TopRight = styled.div`
  flex-grow: 1;
  display: flex;
  height: 100%;
`;

const Bottom = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 2;
  gap: 20px;
`;

const Dashboard = () => {
  const { models } = useModels(); // usefull to load models in cache so it is quickly loaded when user creates a new activity
  return (
    <AppLayout header={<Header />}>
      <Helmet>
        <title>{`SALOMON`}</title>
      </Helmet>
      <Layout>
        <Top>
          <TopLeft>
            <MotivationBlock />
          </TopLeft>
          <TopRight>
            <ImpactBlock />
          </TopRight>
        </Top>
        <Bottom>
          <LastActivities />
        </Bottom>
      </Layout>
    </AppLayout>
  );
};

export default Dashboard;
