import { Component, ReactNode } from 'react';
import {
  Location as RouterLocation,
  Navigate,
  useLocation,
} from 'react-router-dom';

import { useAuthentication } from './context/useAuthentication';
import { useLocalStorage } from 'usehooks-ts';

interface ErrorBoundaryProps {
  children?: ReactNode;
  location: RouterLocation;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

const deleteAllCookiesExceptCookieConsent = () => {
  const cookies = document.cookie.split(";");
  for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i];
      const eqPos = cookie.indexOf("=");
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      if (name !== "CookieConsent") {
        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
      }
  }
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  public state: ErrorBoundaryState = {
    hasError: false,
  };

  public static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    if (error.message === 'Unauthorized') {
      localStorage.removeItem('token');
      deleteAllCookiesExceptCookieConsent();
      return { hasError: true };
    }
    return { hasError: false };
  }

  public render() {
    if (this.state.hasError) {
      return (
        <Navigate
          to="/authentication"
          replace
          state={{
            originLocation: this.props.location,
            error: 'Veuillez vous authentifier pour accéder à la page',
          }}
        />
      );
    }

    return this.props.children;
  }
}

interface AuthenticationGuardProps {
  children: ReactNode;
  suppressError?: boolean;
}

const AuthenticationGuard = ({
  children,
  suppressError,
}: AuthenticationGuardProps) => {
  const [token, setToken] = useLocalStorage<string | null>('token', null);
  const { isAuthenticated, loading } = useAuthentication();
  const location = useLocation();

  if (!token && !loading) {
    if (suppressError) return null;
    return (
      <Navigate
        to="/authentication"
        replace
        state={{ originLocation: location }}
      />
    );
  }

  return <ErrorBoundary location={location}>{children}</ErrorBoundary>;
};

export default AuthenticationGuard;
