import styled from '@emotion/styled';
import { Report } from '@salomon/core';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { HeaderContainer, HeaderSection } from '../layout/Headers';
import UserMenu from '../layout/UserMenu';
import useReport from '../reports/context/useReport';

import { ReactComponent as Home } from '../ui/icons/home.svg';
import { ReactComponent as Next } from '../ui/icons/next.svg';

const BreadcrumbWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const IconContainer = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  * {
    fill: white;
  }
`;

const Breadcrumb = ({
  report,
  projectId,
}: {
  report?: Report;
  projectId?: string;
}) => {
  const navigate = useNavigate();
  const project = report?.projects?.find(
    (project) => project._id.toString() === projectId,
  );
  return (
    <BreadcrumbWrapper>
      <IconContainer
        onClick={() => navigate('/')}
        style={{ width: '36px', height: '36px' }}
      >
        <Home style={{ width: '36px', height: '36px' }} />
      </IconContainer>
      <IconContainer>
        <Next />
      </IconContainer>
      <Link to={`/reports`} className={!!report ? 'h4r underline' : 'h4r'}>
        My reports
      </Link>
      {!!report && (
        <>
          <IconContainer>
            <Next />
          </IconContainer>
          {!project && <p className="h4r">Rapport {report.name}</p>}
          {!!project && (
            <Link to={`/reports/${report._id}`} className="h4r underline">
              Report {report.name}
            </Link>
          )}

          {project && (
            <>
              <IconContainer>
                <Next />
              </IconContainer>
              <p className="h6r">
                {project.model.singularName} - {project.name}
              </p>
            </>
          )}
        </>
      )}
    </BreadcrumbWrapper>
  );
};
export const HeaderReports = () => {
  const { reportId, projectId } = useParams();
  const { report } = useReport(reportId);
  return (
    <HeaderContainer>
      <HeaderSection>
        <Breadcrumb report={report} projectId={projectId} />
      </HeaderSection>
      <HeaderSection>
        <Link to={`/projects`} className="h6r">
          My activities
        </Link>
        <UserMenu />
      </HeaderSection>
    </HeaderContainer>
  );
};
export default HeaderReports;
