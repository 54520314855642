import styled from '@emotion/styled';
import { Project } from '@salomon/core';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import useProjects from '../../../project/context/useProjects';
import CreateProject from '../../../project/CreateProject';
import ProjectCard, { SeeMoreProjects } from '../../../project/ProjectCard';
import { sortByDate } from '../../../project/ProjectsUtils';
import Button from '../../../ui/Button';
import Loader from '../../../ui/Loader';
import useConfiguration from '../../../useConfiguration';

const Container = styled.div`
  width: 100%;
  position: relative;
  gap: 16px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: var(--light-grey);
  background-blend-mode: multiply;
  background-image: url('/mountains.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom center;
`;

const ActivitiesContainer = styled.div`
  width: 100%;
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 16px;
`;

const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ButtonContainer = styled.div`
  align-self: flex-end;
  display: flex;
  gap: 16px;
`;

const NoActivity = styled.div`
  width: min(500px, 90%);
  text-align: justify;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
`;

const MessageContainer = styled.div`
  display: flex;
  text-align: justify;
`;

const LastActivities = () => {
  const [openCreateProject, setOpenCreateProject] = useState(false);

  const { configuration } = useConfiguration();

  const nbProjectsDisplayed = 4;

  const navigate = useNavigate();
  const { projects, loading, remove, count } = useProjects({
    limit: nbProjectsDisplayed,
  });

  if (loading) {
    return (
      <Container>
        <Header>
          <p className="h2b">My Activities</p>
        </Header>
        <Loader fullPage />
      </Container>
    );
  }

  if (!projects || projects.length === 0 || !count) {
    return (
      <Container>
        {openCreateProject && (
          <CreateProject onClose={() => setOpenCreateProject(false)} />
        )}
        <Header>
          <p className="h2b">My Activities</p>
        </Header>
        <ActivitiesContainer>
          <NoActivity>
          <MessageContainer
            dangerouslySetInnerHTML={{
              __html: configuration['projects.introduction'],
            }}
          />
            <Button
              variant="contained"
              color="primary"
              onClick={() => setOpenCreateProject(true)}
            >
              Create your first activity !
            </Button>
          </NoActivity>
        </ActivitiesContainer>
      </Container>
    );
  }

  const handleDeleteProject = (id: string) => async (event: any) => {
    return await remove(id);
  };

  const sortedProjects = projects!.sort((a: Project, b: Project) =>
    sortByDate(a, b),
  );

  const seeMoreButton = count > nbProjectsDisplayed;
  const nbProjectsDisplayedText = count > nbProjectsDisplayed ? `${nbProjectsDisplayed} / ${count}` : count;

  return (
    <Container>
      {openCreateProject && (
        <CreateProject onClose={() => setOpenCreateProject(false)} />
      )}
      <Header>
        <Link to="/projects">
          <p className="h2b">My activities ({nbProjectsDisplayedText})</p>
        </Link>
        <ButtonContainer>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setOpenCreateProject(true)}
            sx={{height:"56px", fontSize: "22px"}}
          >
            Create new activity
          </Button>
        </ButtonContainer>
      </Header>

      <ActivitiesContainer>
        {sortedProjects?.map((project: Project) => (
          <ProjectCard
            key={project._id.toString()}
            project={project}
            onClick={() => navigate(`/project/${project._id}`)}
            onDelete={handleDeleteProject(project._id.toString())}
          />
        ))}
        {seeMoreButton && <SeeMoreProjects number={count - nbProjectsDisplayed} />

        }
      </ActivitiesContainer>
    </Container>
  );
};

export default LastActivities;
