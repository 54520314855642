import styled from '@emotion/styled';
import { ReactNode } from 'react';
import { Link } from 'react-router-dom';

const Container = styled.div`
  width: min(1400px, 100%);
`;

const Main = styled.main`
  width: 100%;
  padding: 32px 0;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 230px);
  background-color: white;
  background-image: url('/mountains.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom center;
  overflow: auto;
`;

const OnboardingFooter = styled.main`
  width: 100%;
  height: 100px;
  background-color: black;
`;

interface OnboardingLayoutProps {
  children: ReactNode;
}

const OnboardingLayout = ({ children }: OnboardingLayoutProps) => {
  return (
    <Container>
      <OnboardingHeader />
      <Main>{children}</Main>
      <OnboardingFooter />
    </Container>
  );
};

const HeaderContainer = styled.div`
  width: 100%;
  height: 130px;
  color: white;
  background-color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  a {
    text-decoration: none;
  }
  .h2r {
    font-size: 21px;
    font-weight: 400;
    letter-spacing: 0.06em;
    text-align: center;
  }
  img {
    width: 100%;
  }
`;

const OnboardingHeader = () => {
  return (
    <HeaderContainer>
      <Link to="/">
        <p className="h2r">ATHLETES CARBON FOOTPRINT</p>
        <img src="/logo white.png" alt="logo salomon" />
      </Link>
    </HeaderContainer>
  );
};

export default OnboardingLayout;
