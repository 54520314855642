import styled from '@emotion/styled';

import { useNavigate } from 'react-router-dom';

import Button from '../ui/Button';
import useConfiguration from '../useConfiguration';
import { useAuthentication } from './context/useAuthentication';

import OnboardingLayout from '../layout/OnboardingLayout';


const Block = styled.div`
  display: flex;
  padding: 32px;
  background-color: white;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: min(90%, 500px);
  gap: 32px;
  > div {
    flex: 1;
    display: flex;
    gap: 16px;
    align-items: center;
  }
`;

const MessageContainer = styled.div`
  display: flex;
  text-align: justify;
`;

const Home = () => {
  const { configuration } = useConfiguration();
  const navigate = useNavigate();
  const { login } = useAuthentication();
  return (
    <OnboardingLayout>
      <Block>
      <MessageContainer
            dangerouslySetInnerHTML={{
              __html: configuration['home.welcomeMessage'],
            }}
          ></MessageContainer>
          {/* <Button
            variant="contained"
            onClick={() => navigate(`/authentication/register`)}
            color="primary"
          >
            Sign up to discover your carbon footprint
          </Button> */}
          <Button variant="contained" onClick={() => login()} color="secondary">
            I have an account, log in
          </Button>
      </Block>
    </OnboardingLayout>
  );
};

export default Home;
